<template>
  <div class="element">
    <input
      :id="id"
      v-on="$listeners"
      v-model="checked"
      class="element__checkbox"
      type="checkbox"
      @change="$emit('input', checked)"
    />
    <label class="element__label" :for="id">
      <slot name="label" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'AuthFormCheckbox',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.element {
  position: relative;

  &__checkbox {
    position: absolute;
    left: -9999px;
  }

  &__label {
    position: relative;
    display: inline-block;
    padding-left: 28px;
    line-height: 20px;
    cursor: pointer;
    color: #363636;
    font-size: 18px;

    @media (min-width: 768px) {
      color: #fff;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      margin-top: 2px;
      border: 1px solid #ddd;
    }

    &::after {
      content: '✓';
      position: absolute;
      top: 2px;
      left: 2px;
      font-size: 18px;
    }

    a {
      color: inherit;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  &__checkbox:not(:checked) + &__label:after {
    opacity: 0;
    transform: scale(0);
  }
  &__checkbox:checked + &__label:after {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
